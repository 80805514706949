
import Vue from 'vue';
import { Diet } from '@/store/modules/food';
import FoodCard from '@/components/modules/food/FoodCard.vue';
import { mapState } from 'vuex';
import { Space } from '@/store/modules/space';

interface Computed {
  currentSpace: Space;
}

interface Props {
  mainDiet: Diet | undefined | null;
  height: number;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'FoodDietCard',

  components: {
    FoodCard
  },

  props: {
    mainDiet: {
      type: Object,
      default: undefined
    },
    height: {
      type: Number,
      default: 300
    }
  },
  computed: {
    ...mapState('UserModule', ['currentSpace'])
  }
});
