
import Vue, { PropType } from 'vue';
import { Vaccine } from '@/store/modules/health';
import { translateArray } from '@/helpers/utils';
import { HeaderItem } from '@/views/Horses.vue';
import { TYPES } from '@/components/modules/health/VaccineVermifugeDetail.vue';
import { mdiMagnify, mdiPhone } from '@mdi/js';

interface Data {
  headers: HeaderItem[];
  search: string;
}

interface Method {
  localizedProtectedAgainst(index: number): string;
}

interface Props {
  vaccines: Vaccine[];
}
interface Computed {
  mdiPhone: string;
  mdiMagnify: string;
}

export default Vue.extend<Data, Method, Computed, Props>({
  name: 'VaccineHistory',
  data: function () {
    return {
      TYPES,
      search: '',
      headers: [
        {
          text: this.$i18n.tc('health-module-screen.vaccine.date-start'),
          align: 'start',
          value: 'dateStart'
        },
        {
          text: this.$i18n.tc('health-module-screen.vaccine.protected-against'),
          value: 'protectedAgainst'
        },
        {
          text: this.$i18n.tc('health-module-screen.vaccine.product'),
          value: 'product'
        },
        {
          text: this.$i18n.tc('health-module-screen.vaccine.batch-number'),
          value: 'batchNumber'
        },
        {
          text: this.$i18n.tc('health-module-screen.vaccine.date-end'),
          value: 'dateEnd'
        },
        {
          text: this.$i18n.tc('health-module-screen.vaccine.veterinary'),
          value: 'veterinary'
        }
      ]
    };
  },
  computed: {
    mdiPhone() {
      return mdiPhone;
    },
    mdiMagnify() {
      return mdiMagnify;
    }
  },
  methods: {
    localizedProtectedAgainst(index: number) {
      if (this.vaccines && this.vaccines[index]) {
        return translateArray(this.vaccines[index].protectedAgainst).join(', ');
      }
      return '';
    }
  },
  props: {
    vaccines: {
      type: Array as PropType<Vaccine[]>,
      default: undefined
    }
  }
});
