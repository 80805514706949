
import Vue from "vue";
import { HealthEntry, HealthReport, Treatment } from "@/store/modules/health";
import HealthReportDetails from "@/components/modules/health/HealthReportDetails.vue";
import {
  mdiFileUploadOutline,
  mdiInformation,
  mdiMagnify,
  mdiMedicalBag,
  mdiPaperclip,
  mdiStethoscope,
  mdiTextBoxPlusOutline,
  mdiToothOutline
} from "@mdi/js";
import FileInput from "@/components/FileInput.vue";
import { showError } from "@/helpers/utils";
import { TYPE } from "@/store/modules/notification";

export interface HeaderItem {
  text: string;
  value: string;
  align?: string;
  sortable?: boolean;
  mobile?: boolean;
  filterable?: boolean;
}

interface Data {
  headers: HeaderItem[];
  search: string;
  showDetails: boolean;
  showFilesDetails: boolean;
  uploadFileDialog: boolean;
  displayEntry: HealthReport;
  loading: boolean;
  fileToUpload: File | null;
  fileName: string;
}

interface Methods {
  clearSearch(): void;
  cancelFileUpload(): void;
  openFileUploadDialog(report: HealthReport): void;
  openDetails(report: HealthReport): void;
  openFilesDetails(report: HealthReport): void;
  uploadFile(): void;
  setFile(file: File): void;
  fetchTreatmentDescription(treatment: Treatment, entry: HealthEntry): string;
  stringOrNull(text?: string): string;
}

interface Computed {
  healthTypeIcon: string;
  mdiMagnify: string;
  mdiInformation: string;
  mdiTextBoxPlusOutline: string;
  mdiFileUploadOutline: string;
  mdiPaperclip: string;
  imageUrl: string;
}

interface Props {
  healthReports: HealthReport[];
  showTitle: boolean;
  title: string;
  showEditControls: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { HealthReportDetails, FileInput },
  data: function () {
    return {
      fileName: '',
      loading: false,
      fileToUpload: null,
      search: '',
      showDetails: false,
      showFilesDetails: false,
      uploadFileDialog: false,
      headers: [
        {
          text: this.$i18n.tc(
            'health-module-screen.health-report.form.conclusion'
          ),
          align: 'start',
          value: 'lastEntry.conclusion',
          sortable: false
        },
        {
          text: this.$i18n.tc(
            'health-module-screen.health-report.form.treatments'
          ),
          value: 'lastEntry.treatments',
          sortable: false
        },
        {
          text: this.$i18n.tc('global.date'),
          value: 'lastEntry.dateStart',
          sortable: true
        },
        {
          text: this.$i18n.tc('app.actions'),
          value: 'showDetails',
          sortable: false
        }
      ],
      displayEntry: {
        id: '1',
        type: '',
        files: [],
        dateStart: '',
        dateCreated: '',
        dateEnd: null,
        lastEntry: {
          dateStart: '',
          dateCreated: '',
          anamnesis: '',
          conclusion: '',
          doctorName: '',
          doctorPhone: '',
          clinicalExam: {
            description: '',
            exams: []
          },
          treatments: []
        },
        entries: []
      }
    };
  },
  methods: {
    fetchTreatmentDescription(treatment: Treatment, entry: HealthEntry) {
      return (
        this.$t('health-module-screen.health-report.form.treatment.use') +
        ' ' +
        this.stringOrNull(treatment.use) +
        ' - ' +
        this.stringOrNull(treatment.name) +
        ' - ' +
        this.stringOrNull(treatment.quantity?.toString()) +
        ' ' +
        this.stringOrNull(treatment.qtdUnit) +
        ' ' +
        this.stringOrNull(treatment.frequency?.toString()) +
        ' ' +
        this.$tc(
          'health-module-screen.health-report.form.treatment.times',
          treatment.frequency ? treatment.frequency : 0
        ) +
        ' ' +
        this.stringOrNull(treatment.freqPeriod) +
        ' ' +
        this.stringOrNull(treatment.duration) +
        ' - ' +
        this.$t('health-module-screen.health-report.form.treatment.start') +
        ' ' +
        this.$dayjs(entry.dateStart).format('L LT')
      );
    },
    stringOrNull(text?: string) {
      return text ? text : '';
    },
    setFile(file) {
      this.fileToUpload = file;
      if (!this.fileName || this.fileName.trim() == '') {
        this.fileName = file.name;
      }
    },
    uploadFile() {
      this.loading = true;
      const entryToUpdate = Object.assign({}, this.displayEntry);
      this.$store
        .dispatch('HealthModule/uploadHealthReportFile', {
          healthReport: entryToUpdate,
          fileToUpload: this.fileToUpload,
          fileName: this.fileName
        })
        .then(() => {
          this.loading = false;
          this.fileToUpload = null;
          this.uploadFileDialog = false;
          this.fileName = '';
          this.$store.commit('NotificationModule/add', {
            msg: this.$t('horse-profile-card.snackbar-success'),
            type: TYPE.SUCCESS
          });
        })
        .catch((error) => {
          showError(error);
          this.loading = false;
        });
    },
    cancelFileUpload() {
      this.fileToUpload = null;
      this.fileName = '';
      this.uploadFileDialog = false;
    },
    clearSearch() {
      this.search = '';
    },
    openFileUploadDialog(report) {
      this.displayEntry = report;
      this.uploadFileDialog = true;
    },
    openDetails(report) {
      this.displayEntry = report;
      this.showDetails = true;
    },
    openFilesDetails(report) {
      this.displayEntry = report;
      this.showFilesDetails = true;
    }
  },
  computed: {
    mdiMagnify() {
      return mdiMagnify;
    },
    mdiInformation() {
      return mdiInformation;
    },
    mdiTextBoxPlusOutline() {
      return mdiTextBoxPlusOutline;
    },
    mdiFileUploadOutline() {
      return mdiFileUploadOutline;
    },
    mdiPaperclip() {
      return mdiPaperclip;
    },
    healthTypeIcon() {
      switch (this.displayEntry.type) {
        case 'health-module-screen.health-report.form.types.veterinary':
          return mdiStethoscope;
        case 'health-module-screen.health-report.form.types.physiotherapy':
          return mdiMedicalBag;
        case 'health-module-screen.health-report.form.types.odontology':
          return mdiToothOutline;
        default:
          return '';
      }
    },
    imageUrl() {
      if (this.fileToUpload) {
        return URL.createObjectURL(this.fileToUpload);
      } else {
        return '';
      }
    }
  },
  props: {
    healthReports: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    showEditControls: {
      type: Boolean,
      default: true
    }
  }
});
