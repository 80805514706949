
import Vue, { PropType } from 'vue';
import { SportActivity } from '@/store/modules/sport';
import {
  mdiBackupRestore,
  mdiCalendarCheck,
  mdiCircleEditOutline,
  mdiDelete,
  mdiMagnify,
  mdiArrowLeftTop
} from '@mdi/js';
import SportActivityEditForm from '@/components/modules/sport/SportActivityEditForm.vue';
import { CalendarEvent } from '@/store/modules/calendar';
import { MODULE_TYPES } from '@/store/constants';
import { ApexOptions } from 'apexcharts';
import { TYPE } from '@/store/modules/notification';
import { showError } from '@/helpers/utils';

export interface HeaderItem {
  text: string;
  value: string;
  align?: string;
  sortable?: boolean;
  mobile?: boolean;
  filterable?: boolean;
}

export interface Data {
  headers: HeaderItem[];
  headersDone: HeaderItem[];
  search: string;
  dialog: boolean;
  dialogDelete: boolean;
  dialogExecute: boolean;
  dialogReturn: boolean;
  editedIndex: number;
  editedItem: SportActivity;
  defaultItem: SportActivity;
  loading: boolean;
  chartOptions: ApexOptions;
  chartOptions3: ApexOptions;
  series: number[];
  series2: { name: string; data: number[] }[];
  doneSeries: number[];
  notDoneSeries: number[];
  isDone: boolean;
  rating: number;
}

interface Methods {
  editItem(item: SportActivity): void;

  deleteItem(item: SportActivity): void;

  executeItem(item: SportActivity, done: boolean): void;

  replicateItem(item: SportActivity): void;

  returnToProgramed(item: SportActivity): void;

  isOverDue(item: SportActivity): boolean;

  deleteItemConfirm(): void;

  executeItemConfirm(): void;

  returnToProgramedConfirm(): void;

  close(): void;

  closeDelete(): void;

  closeReturn(): void;

  closeExecute(): void;

  save(): void;

  resetChart(): void;
}

interface Computed {
  mdiMagnify: string;
  mdiCalendarCheck: string;
  mdiCircleEditOutline: string;
  mdiBackupRestore: string;
  mdiDelete: string;
  mdiArrowLeftTop: string;
  doneItems: SportActivity[];
  overdueItems: SportActivity[];
  notDoneItems: SportActivity[];
  activityRestCount: number;
  activityPlainCount: number;
  activityGinasticCount: number;
  activityJumpCount: number;
  activityOutsideCount: number;
  activityCardioCount: number;
  activityFreeCount: number;
  abstainedActivityRestCount: number;
  abstainedActivityPlainCount: number;
  abstainedActivityGinasticCount: number;
  abstainedActivityJumpCount: number;
  abstainedActivityOutsideCount: number;
  abstainedActivityCardioCount: number;
  abstainedActivityFreeCount: number;
  averageRating: number;
}

interface Props {
  sportActivities: SportActivity[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'SportActivityDetails',
  components: { SportActivityEditForm },
  data: function () {
    return {
      rating: 0,
      isDone: true,
      chartOptions: {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total',
                  color: '#373d3f'
                }
              }
            }
          }
        },
        chart: {
          width: 400,
          type: 'donut',
          sparkline: {
            enabled: true
          }
        },
        title: {
          text: this.$t(
            'sport.activity-details.scheduled-activities'
          ).toString()
        },
        colors: ['#009688', '#cddc39'],
        labels: [
          this.$t('sport.activity-details.accomplished').toString(),
          this.$t('sport.activity-details.cancelled').toString()
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 320
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      },
      series2: [
        {
          name: this.$t('sport.activity-details.accomplished').toString(),
          data: []
        },
        {
          name: this.$t('sport.activity-details.cancelled').toString(),
          data: []
        }
      ],
      chartOptions3: {
        chart: {
          type: 'bar',
          height: 440,
          stacked: true,
          sparkline: {
            enabled: false
          }
        },
        colors: ['#009688', '#cddc39'],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '80%'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },

        grid: {
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        yaxis: {
          min: -10,
          max: 10
          // title: {
          //   text: 'Treino'
          // }
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val: number) {
              return val.toString();
            }
          },
          y: {
            formatter: function (val: number) {
              return Math.abs(val).toString();
            }
          }
        },
        title: {
          text: this.$t(
            'sport.activity-details.activities-scheduled-vs-cancelled'
          ).toString()
        },
        xaxis: {
          categories: [
            this.$t('sport.activity-details.activities-select.rest').toString(),
            this.$t(
              'sport.activity-details.activities-select.plain'
            ).toString(),
            this.$t(
              'sport.activity-details.activities-select.fitness'
            ).toString(),
            this.$t('sport.activity-details.activities-select.jump').toString(),
            this.$t(
              'sport.activity-details.activities-select.outdoor'
            ).toString(),
            this.$t(
              'sport.activity-details.activities-select.cardio'
            ).toString(),
            this.$t(
              'sport.activity-details.activities-select.freedom'
            ).toString()
          ],
          title: {
            text: this.$t('sport.activity-details.frequency').toString()
          },
          labels: {
            formatter: function (val: string) {
              return Math.abs(Math.round(Number(val))).toString();
            }
          }
        }
      },
      series: [],
      doneSeries: [],
      notDoneSeries: [],
      search: '',
      dialog: false,
      loading: false,
      dialogDelete: false,
      dialogExecute: false,
      dialogReturn: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        activity: '',
        duration: '',
        professional: '',
        observation: '',
        dateStart: '',
        rating: 0,
        done: false
      },
      defaultItem: {
        id: '',
        activity: '',
        duration: '',
        professional: '',
        observation: '',
        dateStart: '',
        rating: 0,
        done: false
      },
      headers: [
        {
          text: 'Atividade',
          align: 'start',
          value: 'activity',
          sortable: false
        },
        {
          text: 'Duração',
          value: 'duration',
          sortable: false
        },
        {
          text: 'Responsavel',
          value: 'professional',
          sortable: false
        },
        {
          text: 'Observação',
          value: 'observation',
          sortable: false
        },
        {
          text: this.$i18n.tc('global.date'),
          value: 'date',
          sortable: true
        },
        {
          text: this.$i18n.tc('Ações'),
          value: 'showDetails',
          sortable: false
        }
      ],
      headersDone: [
        {
          text: 'Atividade',
          align: 'start',
          value: 'activity',
          sortable: false
        },
        {
          text: 'Duração',
          value: 'duration',
          sortable: false
        },
        {
          text: 'Responsavel',
          value: 'professional',
          sortable: false
        },
        {
          text: 'Observação',
          value: 'observation',
          sortable: false
        },
        {
          text: this.$i18n.tc('global.date'),
          value: 'date',
          sortable: true
        },
        {
          text: 'Avaliação',
          value: 'rating',
          sortable: true
        },
        {
          text: this.$i18n.tc('Ações'),
          value: 'showDetails',
          sortable: false
        }
      ]
    };
  },
  async created() {
    this.resetChart();
  },
  methods: {
    resetChart() {
      console.log('duane reset chart');
      let salto = this.doneItems.filter(
        (item) =>
          item.activity ===
          this.$t('sport.activity-details.activities-select.jump').toString()
      ).length;

      let salto2 = this.doneItems.filter(
        (item) => item.activity === 'Salto'
      ).length;
      console.log('duane reset chart', salto);
      console.log('duane reset chart', salto2);

      this.series = [this.doneItems.length, this.notDoneItems.length];
      this.doneSeries = [
        this.activityRestCount,
        this.activityPlainCount,
        this.activityGinasticCount,
        this.activityJumpCount,
        this.activityOutsideCount,
        this.activityCardioCount,
        this.activityFreeCount
      ];
      this.notDoneSeries = [
        this.abstainedActivityRestCount,
        this.abstainedActivityPlainCount,
        this.abstainedActivityGinasticCount,
        this.abstainedActivityJumpCount,
        this.abstainedActivityOutsideCount,
        this.abstainedActivityCardioCount,
        this.abstainedActivityFreeCount
      ];
      this.series2[0].data = this.doneSeries;
      this.series2[1].data = this.notDoneSeries;
    },
    isOverDue(item) {
      const today: Date = this.$dayjs();
      const setDate: Date = this.$dayjs(item.dateStart);
      return today >= setDate;
    },
    replicateItem(item) {
      const newItem = Object.assign({}, item);
      this.$emit('replicate-activity-event', newItem);
    },
    returnToProgramed(item) {
      this.editedIndex = this.sportActivities.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogReturn = true;
    },
    editItem(item) {
      this.editedIndex = this.sportActivities.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.sportActivities.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    executeItem(item) {
      this.editedIndex = this.sportActivities.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogExecute = true;
    },
    deleteItemConfirm() {
      this.loading = true;
      const referenceId = this.editedItem.id;
      this.$store
        .dispatch('SportModule/deleteSportActivity', {
          horseId: this.$router.currentRoute.params.horseId,
          sportActivityId: referenceId
        })
        .then(() => {
          this.loading = false;
          this.$emit('delete-calendar-event', referenceId);
          this.$store.commit('NotificationModule/add', {
            msg: 'Atividade excluida com sucesso',
            type: TYPE.SUCCESS
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$rollbar.error(error);
        });

      this.resetChart();
      this.closeDelete();
    },
    returnToProgramedConfirm() {
      const activity = this.sportActivities[this.editedIndex];
      delete activity.done; // AI PRECISA FAZER UM PUT
      activity.rating = 0;
      this.$store
        .dispatch('SportModule/putSportActivity', {
          activity: activity
        })
        .then((res) => {
          Vue.set(this.sportActivities, this.editedIndex, res);
          this.resetChart();
          this.closeReturn();
        })
        .catch((error) => {
          showError(error);
          this.resetChart();
          this.closeReturn();
        });
      this.isDone = true;
      this.rating = 0;
    },
    executeItemConfirm() {
      const itemUnder = this.sportActivities[this.editedIndex];
      itemUnder.done = this.isDone;
      itemUnder.rating = this.rating;
      this.$store
        .dispatch('SportModule/updateSportActivity', {
          activity: itemUnder
        })
        .then(() => {
          this.loading = false;
          this.$store.commit('NotificationModule/add', {
            msg: 'Atividade executada com sucesso',
            type: TYPE.SUCCESS
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$rollbar.error(error);
        });
      this.series = [this.doneItems.length, this.notDoneItems.length];
      this.rating = 0;
      this.isDone = true;
      this.resetChart();
      this.closeExecute();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeReturn() {
      this.dialogReturn = false;
      this.isDone = true;
      this.rating = 0;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeExecute() {
      this.dialogExecute = false;
      this.isDone = true;
      this.rating = 0;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.$store
        .dispatch('SportModule/addSportActivityToHorse', {
          activity: this.editedItem,
          horseId: this.editedItem.horseId
        })
        .then((sportActivity) => {
          this.loading = false;
          const calendarEvent = {
            id: '',
            referenceId: sportActivity.id,
            name: this.editedItem.activity,
            editable: false,
            feature: 'activities',
            moduleName: MODULE_TYPES.SPORT.name,
            color: MODULE_TYPES.SPORT.color,
            icon: MODULE_TYPES.SPORT.icon,
            start: this.$dayjs(this.editedItem.dateStart).format('YYYY-MM-DD'),
            details:
              '<b>ATIVIDADE:</b><ul>' +
              '</li>' +
              '<li>Duração: ' +
              this.editedItem.duration +
              '</li>' +
              '<li>Obs: ' +
              this.editedItem.observation +
              '</li>' +
              '<li>Responsavel: ' +
              this.editedItem.professional +
              '</li></ul>'
          } as CalendarEvent;
          this.$emit('update-calendar-event', calendarEvent);
          this.$store.commit('NotificationModule/add', {
            msg: 'Atividade adicionada com sucesso',
            type: TYPE.SUCCESS
          });
          this.close();
        })
        .catch((error) => {
          this.loading = false;
          this.$rollbar.error(error);
          this.close();
        });
    }
  },
  computed: {
    averageRating() {
      var sum = 0;
      this.doneItems.forEach((item) => {
        sum += item.rating;
      });
      return Number((sum / this.doneItems.length).toFixed(2));
    },
    activityRestCount() {
      return this.doneItems.filter(
        (item) =>
          item.activity ===
          this.$t('sport.activity-details.activities-select.rest').toString()
      ).length;
    },
    activityPlainCount() {
      return this.doneItems.filter(
        (item) =>
          item.activity ===
          this.$t('sport.activity-details.activities-select.plain').toString()
      ).length;
    },
    activityGinasticCount() {
      return this.doneItems.filter(
        (item) =>
          item.activity ===
          this.$t('sport.activity-details.activities-select.fitness').toString()
      ).length;
    },
    activityJumpCount() {
      return this.doneItems.filter(
        (item) =>
          item.activity ===
          this.$t('sport.activity-details.activities-select.jump').toString()
      ).length;
    },
    activityOutsideCount() {
      return this.doneItems.filter(
        (item) =>
          item.activity ===
          this.$t('sport.activity-details.activities-select.outdoor').toString()
      ).length;
    },
    activityCardioCount() {
      return this.doneItems.filter(
        (item) =>
          item.activity ===
          this.$t('sport.activity-details.activities-select.cardio').toString()
      ).length;
    },
    activityFreeCount() {
      return this.doneItems.filter(
        (item) =>
          item.activity ===
          this.$t('sport.activity-details.activities-select.freedom').toString()
      ).length;
    },
    abstainedActivityRestCount() {
      return (
        this.notDoneItems.filter(
          (item) =>
            item.activity ===
            this.$t('sport.activity-details.activities-select.rest').toString()
        ).length * -1
      );
    },
    abstainedActivityPlainCount() {
      return (
        this.notDoneItems.filter(
          (item) =>
            item.activity ===
            this.$t('sport.activity-details.activities-select.plain').toString()
        ).length * -1
      );
    },
    abstainedActivityGinasticCount() {
      return (
        this.notDoneItems.filter(
          (item) =>
            item.activity ===
            this.$t(
              'sport.activity-details.activities-select.fitness'
            ).toString()
        ).length * -1
      );
    },
    abstainedActivityJumpCount() {
      return (
        this.notDoneItems.filter(
          (item) =>
            item.activity ===
            this.$t('sport.activity-details.activities-select.jump').toString()
        ).length * -1
      );
    },
    abstainedActivityOutsideCount() {
      return (
        this.notDoneItems.filter(
          (item) =>
            item.activity ===
            this.$t(
              'sport.activity-details.activities-select.outdoor'
            ).toString()
        ).length * -1
      );
    },
    abstainedActivityCardioCount() {
      return (
        this.notDoneItems.filter(
          (item) =>
            item.activity ===
            this.$t(
              'sport.activity-details.activities-select.cardio'
            ).toString()
        ).length * -1
      );
    },
    abstainedActivityFreeCount() {
      return this.notDoneItems.filter(
        (item) =>
          item.activity ===
          this.$t('sport.activity-details.activities-select.freedom').toString()
      ).length;
    },
    mdiMagnify() {
      return mdiMagnify;
    },
    mdiCalendarCheck() {
      return mdiCalendarCheck;
    },
    mdiCircleEditOutline() {
      return mdiCircleEditOutline;
    },
    mdiBackupRestore() {
      return mdiBackupRestore;
    },
    mdiDelete() {
      return mdiDelete;
    },
    mdiArrowLeftTop() {
      return mdiArrowLeftTop;
    },
    doneItems() {
      return this.sportActivities.filter((item) => {
        if (!item.done) {
          return false;
        }
        return item.done;
      });
    },
    notDoneItems() {
      return this.sportActivities.filter((item) => {
        if (item.done == undefined) {
          return false;
        }
        return !item.done;
      });
    },
    overdueItems() {
      return this.sportActivities.filter((item) => {
        return item.done == null;
      });
    }
  },
  props: {
    sportActivities: {
      type: Array as PropType<SportActivity[]>,
      required: true
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogExecute(val) {
      val || this.closeExecute();
    }
  }
});
