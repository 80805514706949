
import Vue from 'vue';
import { FoodItem } from '@/store/modules/food';
import FoodList from '@/components/modules/food/FoodList.vue';
import { FOOD_TYPES, FoodItemType } from '@/helpers/types';

interface Computed {
  rations: FoodItem[];
  supplements: FoodItem[];
  voluminous: FoodItem[];
  rationType: FoodItemType;
  supplementType: FoodItemType;
  voluminousType: FoodItemType;
}

interface Props {
  foods: FoodItem[];
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'FoodCard',
  components: {
    FoodList
  },
  computed: {
    rations() {
      return this.foods.filter(
        (food) => food.category === 'food-batch-card.categories.ration'
      );
    },
    supplements() {
      return this.foods.filter(
        (food) => food.category === 'food-batch-card.categories.supplement'
      );
    },
    voluminous() {
      return this.foods.filter(
        (food) => food.category === 'food-batch-card.categories.voluminous'
      );
    },
    rationType() {
      return FOOD_TYPES.RATION;
    },
    supplementType() {
      return FOOD_TYPES.SUPPLEMENT;
    },
    voluminousType() {
      return FOOD_TYPES.VOLUMINOUS;
    }
  },
  props: {
    foods: {
      type: Array,
      required: true
    }
  }
});
