
import { Horse } from "@/store/modules/horse";
import { MODULE_TYPES, ModuleItem } from "@/store/constants";
import AvatarCard from "@/components/AvatarCard.vue";
import ModuleFrame from "@/components/modules/ModuleFrame.vue";
import Vue from "vue";
import {
  ExamGlandersEIA,
  HealthEntry,
  HealthReport,
  Vaccine,
  Vermifuge
} from "@/store/modules/health";
import HealthReportTable from "@/components/modules/health/HealthReportTable.vue";
import HealthReportForm from "@/components/modules/health/HealthReportForm.vue";
import HealthCardDetails from "@/components/modules/health/HealthCardDetails.vue";
import { LocalizedSelectItem } from "@/helpers/types";
import VermifugeVaccineHistory from "@/components/modules/health/VermifugeVaccineHistory.vue";
import EiaGlandersDetail from "@/components/modules/health/EiaGlandersDetail.vue";
import { shortStringDots } from "@/helpers/utils";
import VaccineVermifugeDetail from "@/components/modules/health/VaccineVermifugeDetail.vue";
import HealthReportTab from "@/components/modules/health/HealthReportTab.vue";
import { mdiFileOutline, mdiHorseVariant, mdiNeedle, mdiPlus } from "@mdi/js";
import { TYPE as NOTIFICATION_TYPE } from "@/store/modules/notification";
import WeightsDetail from "@/components/modules/health/WeightDetail.vue";

enum TYPE {
  VETERINARY = 'health-module-screen.health-report.form.types.veterinary',
  ODONTOLOGY = 'health-module-screen.health-report.form.types.odontology',
  PHYSIOTHERAPY = 'health-module-screen.health-report.form.types.physiotherapy'
}

interface Data {
  horse: Horse | null;
  allHealthReports: HealthReport[] | undefined;
  vermifuges: Vermifuge[] | undefined;
  vaccines: Vaccine[] | undefined;
  eiaList: ExamGlandersEIA[] | undefined;
  glandersList: ExamGlandersEIA[] | undefined;
  newReportDialog: boolean;
  newEntryDialog: boolean;
  entryType: string;
  pushableReport: HealthReport;
  editableHealthEntry: HealthEntry;
  vermifugeDialog: boolean;
}

interface Methods {
  newReport(): void;

  fileUploaded(): void;

  pushNewEntry(newEntry: HealthReport): void;

  addNewReport(keepOpen: boolean): void;

  addNewEntry(keepOpen: boolean): void;

  loadHealthReports(): void;

  loadVermifuges(): void;

  loadVaccines(): void;

  loadEIA(): void;

  loadGlanders(): void;

  sortVermifuge(items: Vermifuge[]): Vermifuge[];

  sortVaccines(items: Vaccine[]): Vaccine[];

  getMainVaccines(items: Vaccine[]): Vaccine[];

  resetForm(): void;

  loadItems(): void;
}

interface Computed {
  mdiHorseVariant: string;
  mdiFileOutline: string;
  mdiNeedle: string;
  mdiPlus: string;
  health: ModuleItem;
  localizedTypesList: LocalizedSelectItem[];
  currentVetReports: HealthReport[];
  historyVetReports: HealthReport[];
  horseNameTrimmed: string;
}

export default Vue.extend<Data, Methods, Computed, Record<string, never>>({
  data: () => ({
    newReportDialog: false,
    newEntryDialog: false,
    entryType: '',
    allHealthReports: undefined,
    vermifuges: undefined,
    vaccines: undefined,
    eiaList: undefined,
    glandersList: undefined,
    pushableReport: {} as HealthReport,
    editableHealthEntry: {
      dateStart: '',
      dateCreated: '',
      anamnesis: '',
      conclusion: '',
      doctorName: '',
      doctorPhone: '',
      clinicalExam: {
        description: '',
        exams: []
      },
      treatments: []
    },
    notifications: [],
    horse: null,
    vermifugeDialog: false
  }),
  async created() {
    this.loadItems();
  },
  components: {
    WeightsDetail,
    HealthReportTab,
    VaccineVermifugeDetail,
    EiaGlandersDetail,
    VermifugeVaccineHistory,
    HealthCardDetails,
    HealthReportForm,
    HealthReportTable,
    AvatarCard,
    ModuleFrame
  },
  methods: {
    fileUploaded() {
      this.$store.commit('NotificationModule/add', {
        msg: 'Arquivo adicionado com sucesso',
        type: NOTIFICATION_TYPE.SUCCESS
      });
    },
    loadItems() {
      const horseId = this.$router.currentRoute.params.horseId;
      this.horse = this.$store.getters['HorseModule/getById'](horseId);
      this.loadHealthReports();
      this.loadVermifuges();
      this.loadVaccines();
      this.loadEIA();
      this.loadGlanders();
    },
    loadHealthReports() {
      const horseId = this.$router.currentRoute.params.horseId;
      this.$store
        .dispatch('HealthModule/loadHealthReportFromHorse', horseId)
        .then((healthReports) => {
          if (healthReports) {
            this.allHealthReports = healthReports;
          } else {
            this.allHealthReports = [];
          }
        })
        .catch(() => {
          this.allHealthReports = [];
        });
    },
    loadVermifuges() {
      const horseId = this.$router.currentRoute.params.horseId;
      this.$store
        .dispatch('HealthModule/loadVermifugeFromHorse', horseId)
        .then((vermifuges) => {
          if (vermifuges) {
            this.vermifuges = this.sortVermifuge(vermifuges);
          } else {
            this.vermifuges = [];
          }
        })
        .catch(() => {
          this.vermifuges = [];
        });
    },
    loadEIA() {
      const horseId = this.$router.currentRoute.params.horseId;
      this.$store
        .dispatch('HealthModule/loadEIAFromHorse', horseId)
        .then((eiaList) => {
          if (eiaList) {
            this.eiaList = eiaList;
          } else {
            this.eiaList = [];
          }
        })
        .catch(() => {
          this.eiaList = [];
        });
    },
    loadGlanders() {
      const horseId = this.$router.currentRoute.params.horseId;
      this.$store
        .dispatch('HealthModule/loadGlandersFromHorse', horseId)
        .then((glandersList) => {
          if (glandersList) {
            this.glandersList = glandersList;
          } else {
            this.glandersList = [];
          }
        })
        .catch(() => {
          this.glandersList = [];
        });
    },
    loadVaccines() {
      const horseId = this.$router.currentRoute.params.horseId;
      this.$store
        .dispatch('HealthModule/loadVaccinesFromHorse', horseId)
        .then((vaccines) => {
          if (vaccines) {
            this.vaccines = this.sortVaccines(vaccines);
          } else {
            this.vaccines = [];
          }
        })
        .catch(() => {
          this.vaccines = [];
        });
    },
    sortVermifuge(items) {
      if (items && items.length >= 2) {
        return items.sort((a, b) => {
          const timeB = new Date(b.dateEnd);
          const timeA = new Date(a.dateEnd);
          return timeB.getTime() - timeA.getTime();
        });
      }
      return items;
    },
    sortVaccines(items) {
      if (items && items.length >= 2) {
        return items.sort((a, b) => {
          const timeB = new Date(b.dateEnd);
          const timeA = new Date(a.dateEnd);
          return timeB.getTime() - timeA.getTime();
        });
      }
      return items;
    },
    getMainVaccines(items) {
      if (items) {
        return items.filter((vaccine) =>
          vaccine.protectedAgainst.includes(
            'health-batch-card.vaccine.diseases.influenza'
          )
        );
      }
      return items;
    },
    resetForm() {
      this.editableHealthEntry = {
        dateStart: '',
        dateCreated: '',
        anamnesis: '',
        conclusion: '',
        doctorName: '',
        doctorPhone: '',
        clinicalExam: {
          description: '',
          exams: []
        },
        treatments: []
      };
    },
    newReport() {
      this.newReportDialog = true;
    },
    pushNewEntry(newEntry: HealthReport) {
      this.pushableReport = Object.assign({}, newEntry);
      this.newEntryDialog = true;
    },
    addNewReport(keepOpen: boolean) {
      const healthReportNew: HealthReport = {} as HealthReport;
      healthReportNew.entries = [];
      healthReportNew.dateStart = new Date().toISOString().slice(0, 10);
      healthReportNew.dateEnd = !keepOpen
        ? new Date().toISOString().slice(0, 10)
        : null;
      healthReportNew.type = TYPE.VETERINARY;
      healthReportNew.lastEntry = this.editableHealthEntry;
      healthReportNew.entries.push(this.editableHealthEntry);
      this.$store
        .dispatch('HealthModule/addHealthReport', {
          horseId: this.$router.currentRoute.params.horseId,
          healthReport: healthReportNew
        })
        .then(() => {
          this.loadHealthReports();
        })
        .catch((error) => {
          this.$rollbar.error(error);
        });
      this.resetForm();
      this.newReportDialog = false;
      this.entryType = '';
    },
    addNewEntry(keepOpen: boolean) {
      if (!keepOpen) {
        this.pushableReport.dateEnd = new Date().toISOString().slice(0, 10);
      }
      this.pushableReport.lastEntry = this.editableHealthEntry;
      this.pushableReport.entries.push(this.editableHealthEntry);
      this.$store
        .dispatch('HealthModule/updateHealthReport', {
          healthReport: this.pushableReport
        })
        .then(() => {
          this.loadHealthReports();
        })
        .catch((error) => {
          this.$rollbar.error(error);
        });
      this.resetForm();
      this.newEntryDialog = false;
    }
  },
  computed: {
    mdiHorseVariant() {
      return mdiHorseVariant;
    },
    mdiFileOutline() {
      return mdiFileOutline;
    },
    mdiNeedle() {
      return mdiNeedle;
    },
    mdiPlus() {
      return mdiPlus;
    },
    health() {
      return MODULE_TYPES.HEALTH;
    },
    currentVetReports() {
      if (this.allHealthReports) {
        return this.allHealthReports.filter((hr) => {
          if (hr.dateEnd) {
            return false;
          }
          return hr.type === TYPE.VETERINARY;
        });
      } else {
        return [];
      }
    },
    historyVetReports() {
      if (this.allHealthReports) {
        return this.allHealthReports.filter((hr) => {
          if (!hr.dateEnd) {
            return false;
          }
          return hr.type === TYPE.VETERINARY;
        });
      } else {
        return [];
      }
    },
    localizedTypesList() {
      const LocalizedList: LocalizedSelectItem[] = [];
      for (const item of Object.values(TYPE)) {
        let localizedType: LocalizedSelectItem = {
          label: this.$t(item),
          value: item
        };
        LocalizedList.push(localizedType);
      }
      return LocalizedList;
    },
    horseNameTrimmed() {
      return this.horse ? shortStringDots(this.horse.name, 12) : '';
    }
  }
});
