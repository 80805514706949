
import Vue, { PropType } from 'vue';
import { HealthReport } from '@/store/modules/health';
import PlaceHolderNewFeature from '@/components/PlaceHolderNewFeature.vue';
import {
  mdiMedicalBag,
  mdiPill,
  mdiStethoscope,
  mdiToothOutline
} from '@mdi/js';

interface Computed {
  currentTreatment: string;
  mdiStethoscope: string;
  mdiPill: string;
  mdiToothOutline: string;
  mdiMedicalBag: string;
}

interface Props {
  currentVetReports: HealthReport[];
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'HealthCardDetails',
  components: {
    PlaceHolderNewFeature
  },
  computed: {
    mdiStethoscope() {
      return mdiStethoscope;
    },
    mdiPill() {
      return mdiPill;
    },
    mdiToothOutline() {
      return mdiToothOutline;
    },
    mdiMedicalBag() {
      return mdiMedicalBag;
    },
    currentTreatment() {
      let treatments = '';
      if (this.currentVetReports) {
        if (this.currentVetReports[0].lastEntry.treatments.length >= 1) {
          let reportsLenth =
            this.currentVetReports[0].lastEntry.treatments.length;
          this.currentVetReports[0].lastEntry.treatments.forEach(function (
            treatment,
            index
          ) {
            if (treatment.name) {
              if (index + 1 < reportsLenth) {
                treatments = treatments + treatment.name + ', ';
              } else {
                treatments = treatments + treatment.name;
              }
            }
          });
        }
      }
      return treatments;
    }
  },
  props: {
    currentVetReports: {
      type: Array as PropType<HealthReport[]>,
      required: true
    }
  }
});
