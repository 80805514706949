
import Vue, { PropType } from 'vue';
import SportActivityEditForm from '@/components/modules/sport/SportActivityEditForm.vue';
import { MODULE_TYPES, ModuleItem } from '@/store/constants';
import { CalendarEvent } from '@/store/modules/calendar';
import DatePicker from '@/components/horse/DatePicker.vue';
import { SportActivity } from '@/store/modules/sport';

export interface Data {
  routine: SportActivity;
  selectedPanel: number;
  selectOptions: string[];
  characteristics: string[];
  heights: string[];
  categories: string[];
  selectedOption: string;
}

export interface Methods {
  addEvent(): void;
  cancel(): void;
}

interface Computed {
  localCalendarEvent: CalendarEvent;
  sportModule: ModuleItem;
}

interface Props {
  value: CalendarEvent;
  loading: boolean;
  retryActivity: SportActivity;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'SportActivityEditCard',
  components: { SportActivityEditForm, DatePicker },
  data: () => ({
    selectedPanel: 0,
    // selectOptions: ['Treino', 'Prova'],
    selectOptions: ['Treino'],
    characteristics: [
      'Tempo Ideal',
      'Tempo Concedido',
      'Cronômetro',
      'Duas Fases',
      'Desempate'
    ],
    heights: [
      '0,60',
      '0,70',
      '0,80',
      '0,90',
      '1,00',
      '1,10',
      '1,20',
      '1,30',
      '1,40',
      '1,45',
      '1,50',
      '1,55',
      'Mini GP',
      'GP',
      'Derby'
    ],
    categories: [
      'CN04',
      'CN05',
      'CN06',
      'CN07',
      'Sênior',
      'Under 25',
      'Young Rider',
      'Júnior',
      'Pré-Júnior',
      'Mirim',
      'Pré-Mirim',
      'Mini Mirim',
      'JC',
      'JC Top',
      'JC A',
      'JC b',
      'Amador Top',
      'Amador',
      'Amador A',
      'Amador B',
      'Máster',
      'Máster Top',
      'Máster A',
      'Máster B',
      'Amazonas',
      'Amazonas Top',
      'Amazonas A',
      'Série Intermediária',
      'Série Principal'
    ],
    selectedOption: '',
    routine: {
      activity: '',
      duration: '',
      observation: '',
      dateStart: '',
      professional: '',
      rating: 0,
      done: false
    } as SportActivity
  }),
  async created() {
    if (this.retryActivity.activity !== '') {
      this.routine.activity = this.retryActivity.activity;
      this.routine.professional = this.retryActivity.professional;
      this.routine.duration = this.retryActivity.duration;
      this.routine.observation = this.retryActivity.observation;
      this.selectedOption = 'Treino';
      this.selectedPanel = 0;
    }
  },
  methods: {
    addEvent() {
      const newRoutine = Object.assign({}, this.routine);
      newRoutine.dateStart = this.$dayjs(newRoutine.dateStart).format(
        'YYYY-MM-DD'
      );
      this.routine.activity = '';
      this.routine.duration = '';
      this.routine.observation = '';
      this.routine.dateStart = '';
      this.routine.professional = '';
      this.routine.rating = 0;
      this.selectedOption = '';
      this.$emit('save', newRoutine);
    },
    cancel() {
      this.selectedOption = '';
      this.routine = {
        activity: '',
        duration: '',
        observation: '',
        dateStart: '',
        professional: '',
        rating: 0,
        done: false
      } as SportActivity;
      this.$emit('cancel');
    }
  },
  computed: {
    sportModule() {
      return MODULE_TYPES.SPORT;
    },
    localCalendarEvent: {
      get() {
        return this.value;
      }
    }
  },
  props: {
    value: {
      type: Object as PropType<CalendarEvent>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    retryActivity: {
      type: Object as PropType<SportActivity>,
      required: true
    }
  },
  watch: {
    localCalendarEvent: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true
    },
    retryActivity: {
      handler(newVal) {
        if (newVal.activity !== '') {
          this.routine.activity = newVal.activity;
          this.routine.professional = newVal.professional;
          this.routine.duration = newVal.duration;
          this.routine.observation = newVal.observation;
          this.selectedOption = 'Treino';
          this.selectedPanel = 0;
        }
      },
      deep: true
    },
    routine: {
      handler(newVal) {
        this.localCalendarEvent.start = this.$dayjs(newVal.dateStart).format(
          'YYYY-MM-DD'
        );
        this.localCalendarEvent.details =
          '<b>ATIVIDADE:</b><ul>' +
          '</li>' +
          '<li>Duração: ' +
          newVal.duration +
          '</li>' +
          '<li>Obs: ' +
          newVal.observation +
          '</li>' +
          '<li>Responsavel: ' +
          newVal.professional +
          '</li></ul>';

        this.localCalendarEvent.name = newVal.activity;
      },
      deep: true
    }
  }
});
