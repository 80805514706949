
import Vue, { PropType } from 'vue';
import { Vaccine, Vermifuge } from '@/store/modules/health';
import VermifugeHistory from '@/components/modules/health/VermifugeHistory.vue';
import VaccineHistory from '@/components/modules/health/VaccineHistory.vue';
import { MODULE_TYPES, ModuleItem } from '@/store/constants';
import { mdiClose, mdiNeedle } from '@mdi/js';

interface Computed {
  health: ModuleItem;
  mdiClose: string;
  mdiNeedle: string;
}

interface Props {
  vermifuges: Vermifuge[];
  vaccines: Vaccine[];
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'VermifugeVaccineHistory',
  components: {
    VermifugeHistory,
    VaccineHistory
  },
  computed: {
    mdiClose() {
      return mdiClose;
    },
    mdiNeedle() {
      return mdiNeedle;
    },
    health() {
      return MODULE_TYPES.HEALTH;
    }
  },
  props: {
    vermifuges: {
      type: Array as PropType<Vermifuge[]>,
      required: true
    },
    vaccines: {
      type: Array as PropType<Vaccine[]>,
      required: true
    }
  }
});
