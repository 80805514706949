
import { Horse } from "@/store/modules/horse";
import { MODULE_TYPES, ModuleItem } from "@/store/constants";
import AvatarCard from "@/components/AvatarCard.vue";
import ModuleFrame from "@/components/modules/ModuleFrame.vue";
import DisplayInfo from "@/components/DisplayInfo.vue";
import Vue from "vue";
import { ERROR_LIST } from "@/helpers/errors";
import { HorseShoe } from "@/store/modules/horseShoe";
import { mapState } from "vuex";
import { Space } from "@/store/modules/space";
import { shortStringDots, showError } from "@/helpers/utils";
import { HeaderItem } from "@/views/Horses.vue";
import { SPACES_HOME } from "@/router";
import {
  mdiCancel,
  mdiCheckboxMarkedCircle,
  mdiCircleEditOutline,
  mdiHorseVariant,
  mdiMagnify
} from "@mdi/js";

interface Data {
  horse: Horse | null;
  currentHorseShoe: HorseShoe | null | undefined;
  historyHorseShoe: HorseShoe[] | null | undefined;
  localCurrentHorseShoe: HorseShoe | null | undefined;
  localHistoryHorseShoe: HorseShoe[] | null | undefined;
  editableObservation: boolean;
  editableHistoryObs: boolean[];
  headers: HeaderItem[];
}

interface Methods {
  duration(historyItem: HorseShoe): number;

  updateObservation(): void;

  updateHistoryObs(historyItem: HorseShoe, index: number): void;

  toggleEdit(index: number): void;

  cancelCurrentEditing(): void;

  cancelHistoryEditing(index: number): void;

  loadItems(): void;
}

interface Computed {
  horseShoe: ModuleItem;
  currentSpace: Space | null;
  horseNameTrimmed: string;
  daysInUse: string;
  mdiMagnify: string;
  mdiCircleEditOutline: string;
  mdiCheckboxMarkedCircle: string;
  mdiCancel: string;
  mdiHorseVariant: string;
}

export default Vue.extend<Data, Methods, Computed, Record<string, never>>({
  name: 'HorseShoe',
  data: function () {
    return {
      search: '',
      horse: null,
      editableObservation: false,
      editableHistoryObs: [],
      currentHorseShoe: undefined,
      historyHorseShoe: undefined,
      localHistoryHorseShoe: undefined,
      localCurrentHorseShoe: undefined,
      headers: [
        {
          text: this.$i18n.tc('horse-shoe.table.start-date'),
          value: 'dateStart',
          sortable: true
        },
        {
          text: this.$i18n.tc('horse-shoe.table.type'),
          value: 'type',
          sortable: false
        },
        {
          text: this.$i18n.tc('horse-shoe.table.duration'),
          value: 'datePredictableEnd',
          sortable: false
        },
        {
          text: this.$i18n.tc('horse-shoe.form.observation'),
          value: 'observations',
          sortable: false
        }
      ]
    };
  },
  async created() {
    this.loadItems();
  },
  components: {
    AvatarCard,
    ModuleFrame,
    DisplayInfo
  },
  methods: {
    loadItems() {
      const horseId = this.$router.currentRoute.params.horseId;
      this.horse = this.$store.getters['HorseModule/getById'](horseId);
      if (!this.horse) {
        const spaceId = this.$router.currentRoute.params.spaceId;
        this.$router.push({
          name: SPACES_HOME,
          params: { spaceId: spaceId },
          query: { error: ERROR_LIST.HORSE_NO_EXISTS.code.toString() }
        });
      } else {
        this.$store
          .dispatch('HorseShoeModule/loadHorseShoeFromHorse', horseId)
          .then((horseShoe) => {
            this.currentHorseShoe = horseShoe.current;
            this.historyHorseShoe = horseShoe.history ? horseShoe.history : [];
            this.localCurrentHorseShoe = Object.assign(
              {},
              this.currentHorseShoe
            );
            this.localHistoryHorseShoe = [];
            this.editableHistoryObs = [];
            if (horseShoe.history) {
              for (let oldHorseSHoe of horseShoe.history) {
                this.editableHistoryObs.push(false);
                this.localHistoryHorseShoe.push(
                  Object.assign({}, oldHorseSHoe)
                );
              }
            }
          })
          .catch(() => {
            this.currentHorseShoe = null;
            this.historyHorseShoe = [];
          });
      }
    },
    duration(horseShoe: HorseShoe) {
      return this.$store.getters['HorseShoeModule/getDuration'](horseShoe);
    },
    toggleEdit(index: number) {
      this.$set(
        this.editableHistoryObs,
        index,
        !this.editableHistoryObs[index]
      );
    },
    updateObservation() {
      this.$store
        .dispatch('HorseShoeModule/updateHorseShoe', {
          horseShoe: this.currentHorseShoe
        })
        .then((res) => {
          this.editableObservation = false;
          if (this.localCurrentHorseShoe) {
            this.localCurrentHorseShoe.observations = res.observations;
          }
        })
        .catch((error) => {
          showError(error);
        });
    },
    updateHistoryObs(horseShoe: HorseShoe, index: number) {
      this.$store
        .dispatch('HorseShoeModule/updateHorseShoe', {
          horseShoe: horseShoe
        })
        .then((res) => {
          this.toggleEdit(index);
          if (this.localHistoryHorseShoe && this.localHistoryHorseShoe[index]) {
            this.localHistoryHorseShoe[index].observations = res.observations;
          }
        })
        .catch((error) => {
          showError(error);
        });
    },
    cancelCurrentEditing() {
      this.editableObservation = false;
      if (this.localCurrentHorseShoe && this.currentHorseShoe) {
        this.currentHorseShoe.observations =
          this.localCurrentHorseShoe.observations;
      }
    },
    cancelHistoryEditing(index: number) {
      this.toggleEdit(index);
      if (this.historyHorseShoe && this.localHistoryHorseShoe) {
        this.historyHorseShoe[index].observations =
          this.localHistoryHorseShoe[index].observations;
      }
    }
  },
  computed: {
    mdiHorseVariant() {
      return mdiHorseVariant;
    },
    mdiMagnify() {
      return mdiMagnify;
    },
    mdiCircleEditOutline() {
      return mdiCircleEditOutline;
    },
    mdiCheckboxMarkedCircle() {
      return mdiCheckboxMarkedCircle;
    },
    mdiCancel() {
      return mdiCancel;
    },
    horseShoe() {
      return MODULE_TYPES.HORSESHOE;
    },
    daysInUse() {
      const now = this.$dayjs();
      const dateStart = this.$dayjs(this.currentHorseShoe?.dateStart);
      return now.diff(dateStart, 'day') > 1 ? now.diff(dateStart, 'day') : '1';
    },
    horseNameTrimmed() {
      return this.horse ? shortStringDots(this.horse.name, 12) : '';
    },
    ...mapState('UserModule', ['currentSpace'])
  }
});
