
import Vue, { PropType } from 'vue';
import { SportActivity } from '@/store/modules/sport';
import DatePicker from '@/components/horse/DatePicker.vue';

interface Data {
  durationOptions: string[];
  activityOptions: string[];
}

interface Computed {
  localRoutine: SportActivity;
  isOverDue: boolean;
}

interface Props {
  value: SportActivity;
  loading: boolean;
}

export default Vue.extend<Data, Record<string, never>, Computed, Props>({
  name: 'SportActivityEditForm',
  components: { DatePicker },
  data: () => ({
    creating: false,
    durationOptions: ['-', '30min', '45min', '1h', '1h30', '2h'],
    activityOptions: [
      '-',
      'Descanso',
      'Plano',
      'Ginástica',
      'Salto',
      'Exterior',
      'Cardiovascular',
      'Liberdade'
    ]
  }),
  computed: {
    isOverDue() {
      if (
        this.localRoutine.dateStart === null ||
        this.localRoutine.dateStart === ''
      )
        return false;
      const today: Date = this.$dayjs();
      const setDate: Date = this.$dayjs(this.localRoutine.dateStart);
      return today >= setDate;
    },
    localRoutine: {
      get() {
        return this.value;
      }
    }
  },
  props: {
    value: {
      type: Object as PropType<SportActivity>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    localRoutine: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true
    }
  }
});
