
import Vue from "vue";
import { HealthEntry, HealthReport, Treatment } from "@/store/modules/health";
import { MODULE_TYPES, ModuleItem } from "@/store/constants";
import { mdiPaperclip, mdiPhone, mdiPill } from "@mdi/js";

interface Methods {
  fetchTreatmentDescription(treatment: Treatment, entry: HealthEntry): string;
  stringOrNull(text?: string): string;
}

interface Computed {
  healthModule: ModuleItem;
  mdiPhone: string;
  mdiPill: string;
  mdiPaperclip: string;
}

interface Props {
  healthReport: HealthReport;
}

export default Vue.extend<Record<string, never>, Methods, Computed, Props>({
  methods: {
    fetchTreatmentDescription(treatment: Treatment, entry: HealthEntry) {
      return (
        this.$t('health-module-screen.health-report.form.treatment.use') +
        ' ' +
        this.stringOrNull(treatment.use) +
        ' - ' +
        this.stringOrNull(treatment.name) +
        ' - ' +
        this.stringOrNull(treatment.quantity?.toString()) +
        ' ' +
        this.stringOrNull(treatment.qtdUnit) +
        ' ' +
        this.stringOrNull(treatment.frequency?.toString()) +
        ' ' +
        this.$tc(
          'health-module-screen.health-report.form.treatment.times',
          treatment.frequency ? treatment.frequency : 0
        ) +
        ' ' +
        this.stringOrNull(treatment.freqPeriod) +
        ' ' +
        this.stringOrNull(treatment.duration) +
        ' - ' +
        this.$t('health-module-screen.health-report.form.treatment.start') +
        ' ' +
        this.$dayjs(entry.dateStart).format('L LT')
      );
    },
    stringOrNull(text?: string) {
      return text ? text : '';
    }
  },
  computed: {
    mdiPhone() {
      return mdiPhone;
    },
    mdiPill() {
      return mdiPill;
    },
    mdiPaperclip() {
      return mdiPaperclip;
    },
    healthModule() {
      return MODULE_TYPES.HEALTH;
    }
  },
  props: {
    healthReport: {
      type: Object,
      required: true
    }
  }
});
