
import Vue, { PropType } from "vue";
import { ModuleItem } from "@/store/constants";
import { mdiPlus } from "@mdi/js";
import { HorseItem } from "@/views/Horses.vue";
import { Horse } from "@/store/modules/horse";
import HealthBatch from "@/components/modules/health/HealthBatch.vue";
import FoodBatch from "@/components/modules/food/FoodBatch.vue";
import HorseShoeBatch from "@/components/modules/horseShoe/HorseShoeBatch.vue";

interface Props {
  type: ModuleItem;
  horse: Horse;
}

interface Computed {
  backgroundColor: string;
  mdiPlus: string;
  selectedHorse: HorseItem[];
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'ModuleFrame',
  components: {
    HealthBatch,
    HorseShoeBatch,
    FoodBatch
  },
  props: {
    type: {
      type: Object as PropType<ModuleItem>,
      required: true
    },
    horse: {
      type: Object as PropType<Horse>,
      required: true
    }
  },
  computed: {
    backgroundColor() {
      return 'background-color: ' + this.type.color;
    },
    mdiPlus() {
      return mdiPlus;
    },
    selectedHorse() {
      let selectedHorse = [];
      let horse = {
        stableName: '',
        stableId: 0,
        horse: this.horse
      } as HorseItem;
      selectedHorse.push(horse);
      return selectedHorse;
    }
  }
});
