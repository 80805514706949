
import Vue from "vue";
import PlaceHolderNewFeature from "@/components/PlaceHolderNewFeature.vue";
import { mdiFileMultiple, mdiMedicalBag, mdiScale, mdiStethoscope, mdiToothOutline } from "@mdi/js";

interface Props {
  showExam: boolean;
  showWeightins: boolean;
}

interface Computed {
  mdiStethoscope: string;
  mdiScale: string;
  mdiMedicalBag: string;
  mdiToothOutline: string;
  mdiFileMultiple: string;
}

export default Vue.extend<
  Record<string, never>,
  Record<string, never>,
  Computed,
  Props
>({
  name: 'HealthReportTab',
  components: {
    PlaceHolderNewFeature
  },
  computed: {
    mdiStethoscope() {
      return mdiStethoscope;
    },
    mdiScale() {
      return mdiScale;
    },
    mdiMedicalBag() {
      return mdiMedicalBag;
    },
    mdiToothOutline() {
      return mdiToothOutline;
    },
    mdiFileMultiple() {
      return mdiFileMultiple;
    }
  },
  props: {
    showExam: {
      type: Boolean,
      default: true
    },
    showWeightins: {
      type: Boolean,
      default: false
    }
  }
});
