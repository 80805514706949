
import Vue, { PropType } from 'vue';
import { Vaccine, Vermifuge } from '@/store/modules/health';
import { translateArray } from '@/helpers/utils';
import { HeaderItem } from '@/views/Horses.vue';
import { mdiInformation } from '@mdi/js';

interface Item {
  type: string;
  dateStart: string;
  product: string;
  dateEnd: string;
  protected: string;
  batchNumber?: string;
}

export enum TYPES {
  VERMIFUGE = 'health-module-screen.vermifuge.title',
  VACCINE = 'health-module-screen.vaccine.title'
}

interface Data {
  TYPES: Record<string, unknown>;
  headers: HeaderItem[];
}

interface Computed {
  localizedProtectedAgainst: string;
  localizedActivePrinciple: string;
  items: Item[];
  mdiInformation: string;
}

interface Props {
  vaccines: Vaccine[] | undefined | null;
  vermifuges: Vermifuge[] | undefined | null;
}

export default Vue.extend<Data, Record<string, never>, Computed, Props>({
  name: 'VaccineVermifugeDetail',
  data: function () {
    return {
      TYPES,
      headers: [
        {
          text: '',
          align: 'start',
          value: 'type'
        },
        {
          text: this.$i18n.tc('health-module-screen.vaccine.date-start'),
          value: 'dateStart'
        },
        {
          text: this.$i18n.tc('health-module-screen.vaccine.product'),
          value: 'product'
        },
        {
          text: this.$i18n.tc('health-module-screen.vaccine.date-end'),
          value: 'dateEnd'
        },
        {
          text: this.$i18n.tc('health-module-screen.vaccine.batch-number'),
          value: 'batchNumber'
        }
      ]
    };
  },
  computed: {
    mdiInformation() {
      return mdiInformation;
    },
    items() {
      const items: Item[] = [];
      if (this.vermifuges && this.vermifuges.length) {
        const verimfuge: Item = {
          type: TYPES.VERMIFUGE,
          dateStart: this.$dayjs(this.vermifuges[0].dateStart).format('L'),
          dateEnd: this.$dayjs(this.vermifuges[0].dateEnd).format('L'),
          product: this.vermifuges[0].drug,
          protected: translateArray(this.vermifuges[0].activePrinciple).join(
            ', '
          )
        };
        items.push(verimfuge);
      }
      if (this.vaccines && this.vaccines.length) {
        const vaccine: Item = {
          type: TYPES.VACCINE,
          dateStart: this.$dayjs(this.vaccines[0].dateStart).format('L'),
          dateEnd: this.$dayjs(this.vaccines[0].dateEnd).format('L'),
          product: this.vaccines[0].product,
          batchNumber: this.vaccines[0].batchNumber,
          protected: translateArray(this.vaccines[0].protectedAgainst).join(
            ', '
          )
        };
        items.push(vaccine);
      }
      return items;
    },
    localizedProtectedAgainst() {
      if (this.vaccines && this.vaccines[0]) {
        return translateArray(this.vaccines[0].protectedAgainst).join(', ');
      }
      return '';
    },
    localizedActivePrinciple() {
      if (this.vermifuges && this.vermifuges[0]) {
        return translateArray(this.vermifuges[0].activePrinciple).join(', ');
      }
      return '';
    }
  },
  props: {
    vaccines: {
      type: Array as PropType<Vaccine[]>,
      default: undefined
    },
    vermifuges: {
      type: Array as PropType<Vermifuge[]>,
      default: undefined
    }
  }
});
