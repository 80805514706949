
import Vue, { PropType } from "vue";
import { HealthEntry, Treatment } from "@/store/modules/health";
import { Validation, validationMixin } from "vuelidate";
import vuelidateMixin from "@/helpers/vuelidateMixin";
import { required } from "vuelidate/lib/validators";
import { mdiClose, mdiPhone, mdiPill, mdiPlus } from "@mdi/js";
import DatePicker from "@/components/horse/DatePicker.vue";

interface Data {
  creating: boolean;
  useList: string[];
  drugUnitList: string[];
  shouldCloseReport: boolean | null;
}

interface Computed {
  localHealthEntry: HealthEntry;
  mdiClose: string;
  mdiPill: string;
  mdiPlus: string;
  mdiPhone: string;
}

interface Methods {
  save(): void;

  cancel(): void;

  addNewTreatment(): void;

  removeTreatment(index: number): void;
}

interface Props {
  value: HealthEntry;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'HealthReportForm',
  components: { DatePicker },
  mixins: [validationMixin, vuelidateMixin],
  validations: {
    localHealthEntry: {
      conclusion: { required }
    },
    shouldCloseReport: { required }
  },
  data: () => ({
    creating: false,
    shouldCloseReport: null,
    useList: ['Endovenoso', 'Intramuscular', 'Oral', 'Oftalmico', 'Local'],
    drugUnitList: ['mg', 'g', 'ml', 'gotas', 'outro']
  }),
  methods: {
    save() {
      this.$v.$touch();
      (this.$refs.datePicker as Validation).$v.$touch();
      if (
        !this.$v.$invalid &&
        !(this.$refs.datePicker as Validation).$v.$invalid
      ) {
        this.creating = true;
        this.localHealthEntry.dateCreated = new Date().toISOString();
        if (this.shouldCloseReport) {
          this.$emit('new-entry-saved', false);
        } else {
          this.$emit('new-entry-saved', true);
        }
        this.creating = false;
        this.shouldCloseReport = null;
        const datePickerComponent = this.$refs.datePicker as Vue &
          Validation & {
            dateDisplay: string;
            datePicker: string;
          };
        datePickerComponent.dateDisplay = '';
        datePickerComponent.datePicker = '';
        this.$v.$reset();
        datePickerComponent.$v.$reset();
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    addNewTreatment() {
      const newTreatment: Treatment = {} as Treatment;
      this.localHealthEntry.treatments.push(newTreatment);
    },
    removeTreatment(index) {
      this.localHealthEntry.treatments.splice(index, 1);
    }
  },
  computed: {
    mdiPill() {
      return mdiPill;
    },
    mdiPhone() {
      return mdiPhone;
    },
    mdiPlus() {
      return mdiPlus;
    },
    mdiClose() {
      return mdiClose;
    },
    localHealthEntry: {
      get() {
        return this.value;
      }
    }
  },
  props: {
    value: {
      type: Object as PropType<HealthEntry>,
      required: true
    }
  },
  watch: {
    localHealthEntry: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true
    }
  }
});
