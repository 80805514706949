
import Vue, { PropType } from 'vue';
import { FoodItem } from '@/store/modules/food';
import FoodDetailTable from '@/components/modules/food/FoodDetailTable.vue';
import { mdiInformation } from '@mdi/js';

interface Methods {
  foodQtdAndFrequency(food: FoodItem): string;
}

interface Props {
  mainHeader: string;
  detailHeader: string;
  foods: FoodItem[];
  defaultIcon: string;
}
interface Computed {
  mdiInformation: string;
}

export default Vue.extend<Record<string, never>, Methods, Computed, Props>({
  name: 'FoodList',
  components: {
    FoodDetailTable
  },
  methods: {
    foodQtdAndFrequency(food: FoodItem) {
      return this.$store.getters['FoodModule/getFoodQtdAndFrequency'](food);
    }
  },
  computed: {
    mdiInformation() {
      return mdiInformation;
    }
  },
  props: {
    mainHeader: {
      type: String,
      default: ''
    },
    detailHeader: {
      type: String,
      default: ''
    },
    foods: {
      type: Array as PropType<FoodItem[]>,
      required: true
    },
    defaultIcon: {
      type: String,
      default: ''
    }
  }
});
