
import { Horse, HorseWeight } from "@/store/modules/horse";
import { MODULE_TYPES, ModuleItem } from "@/store/constants";
import AvatarCard from "@/components/AvatarCard.vue";
import ModuleFrame from "@/components/modules/ModuleFrame.vue";
import FoodDietCard from "@/components/modules/food/FoodDietCard.vue";
import FoodCard from "@/components/modules/food/FoodCard.vue";
import Vue from "vue";
import { ERROR_LIST } from "@/helpers/errors";
import { Diet, FoodItem } from "@/store/modules/food";
import { shortStringDots } from "@/helpers/utils";
import { SPACES_HOME } from "@/router";
import { mdiHorseVariant } from "@mdi/js";

interface Data {
  horse: Horse | null;
  rationDate: number | null;
  dietMocked: Diet;
  dietCurrent: Diet | null | undefined;
  dietHistory: Diet[] | null | undefined;
}

interface Computed {
  food: ModuleItem;
  mockedSponsored: FoodItem[];
  horseNameTrimmed: string;
  mdiHorseVariant: string;
  actualWeight: string;
  actualWeightInDate: string;
  pastWeight: string;
  pastWeightInDate: string;
  sortedWeights: HorseWeight[];
}

interface Methods {
  getFoodItemText(diet: Diet): string;
  loadItems(): void;
}

export default Vue.extend<Data, Methods, Computed, Record<string, never>>({
  name: 'Food',
  data: () => ({
    horse: null,
    rationDate: null,
    dietCurrent: undefined,
    dietHistory: undefined,
    dietMocked: {
      id: '1',
      dateStart: new Date(),
      foodItems: []
    }
  }),
  async created() {
    this.dietMocked.foodItems = this.mockedSponsored;
    const horseId = this.$router.currentRoute.params.horseId;
    this.horse = this.$store.getters['HorseModule/getById'](horseId);
    if (!this.horse) {
      const spaceId = this.$router.currentRoute.params.spaceId;
      this.$router.push({
        name: SPACES_HOME,
        params: { spaceId: spaceId },
        query: { error: ERROR_LIST.HORSE_NO_EXISTS.code.toString() }
      });
    }
    this.loadItems();
  },
  components: {
    AvatarCard,
    ModuleFrame,
    FoodDietCard,
    FoodCard
  },
  computed: {
    sortedWeights() {
      let weights = this.horse?.weights?.slice();
      if (weights) {
        weights.slice().sort(function (a, b) {
          const timeB = new Date(b.weightInDate);
          const timeA = new Date(a.weightInDate);
          return timeB.getTime() - timeA.getTime();
        });
      } else {
        weights = [];
      }
      return weights;
    },
    actualWeight() {
      if (this.sortedWeights && this.sortedWeights[0] != null) {
        return this.sortedWeights[0].weight + 'KG';
      } else return '';
    },
    actualWeightInDate() {
      if (this.sortedWeights && this.sortedWeights[0] != null) {
        return this.$dayjs(this.sortedWeights[0].weightInDate).format('L');
      } else return '';
    },
    pastWeight() {
      if (this.sortedWeights && this.sortedWeights[1] != null) {
        return this.sortedWeights[1].weight + 'KG';
      } else return '';
    },
    pastWeightInDate() {
      if (this.sortedWeights && this.sortedWeights[1] != null) {
        return this.$dayjs(this.sortedWeights[1].weightInDate).format('L');
      } else return '';
    },
    mdiHorseVariant() {
      return mdiHorseVariant;
    },
    food() {
      return MODULE_TYPES.FOOD;
    },
    mockedSponsored() {
      return this.$store.getters['FoodSponsoredModule/foods'];
    },
    horseNameTrimmed() {
      return this.horse ? shortStringDots(this.horse.name, 12) : '';
    }
  },
  methods: {
    getFoodItemText(diet) {
      let items = diet.foodItems.map((item) => this.$t(item.category));
      return items.join(', ');
    },
    loadItems() {
      this.$store
        .dispatch('FoodModule/loadDietFromHorse', this.horse?.id)
        .then((diet) => {
          this.dietCurrent = diet.current;
          if (!diet.history) {
            this.dietHistory = [];
            return;
          }
          this.dietHistory =
            diet.history.length < 2
              ? diet.history
              : diet.history.sort((a: Diet, b: Diet) => {
                  const timeB = b.dateEnd ? new Date(b.dateEnd).getTime() : 0;
                  const timeA = a.dateEnd ? new Date(a.dateEnd).getTime() : 0;
                  return timeB - timeA;
                });
        })
        .catch(() => {
          this.dietCurrent = null;
          this.dietHistory = [];
        });
    }
  }
});
