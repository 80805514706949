
import Vue, { PropType } from 'vue';
import { Vermifuge } from '@/store/modules/health';
import { translateArray } from '@/helpers/utils';
import { HeaderItem } from '@/views/Horses.vue';
import { mdiMagnify, mdiPhone } from '@mdi/js';

interface Data {
  headers: HeaderItem[];
  search: string;
}

interface Method {
  localizedActivePrinciple(index: number): string;
}

interface Props {
  vermifuges: Vermifuge[];
}

interface Computed {
  mdiMagnify: string;
  mdiPhone: string;
}

export default Vue.extend<Data, Method, Computed, Props>({
  name: 'VermifugeHistory',
  computed: {
    mdiMagnify() {
      return mdiMagnify;
    },
    mdiPhone() {
      return mdiPhone;
    }
  },
  data: function () {
    return {
      search: '',
      headers: [
        {
          text: this.$i18n.tc('health-module-screen.vermifuge.date-start'),
          align: 'start',
          value: 'dateStart'
        },
        {
          text: this.$i18n.tc(
            'health-module-screen.vermifuge.active-principle'
          ),
          value: 'activePrinciple'
        },
        {
          text: this.$i18n.tc('health-module-screen.vermifuge.product'),
          value: 'drug'
        },
        {
          text: this.$i18n.tc('health-module-screen.vermifuge.date-end'),
          value: 'dateEnd'
        },
        {
          text: this.$i18n.tc('health-module-screen.vaccine.veterinary'),
          value: 'veterinary'
        }
      ]
    };
  },
  methods: {
    localizedActivePrinciple(index: number) {
      if (this.vermifuges && this.vermifuges[index]) {
        return translateArray(this.vermifuges[index].activePrinciple).join(
          ', '
        );
      }
      return '';
    }
  },
  props: {
    vermifuges: {
      type: Array as PropType<Vermifuge[]>,
      default: undefined
    }
  }
});
