
import ModuleFrame from '@/components/modules/ModuleFrame.vue';
import { MODULE_TYPES, ModuleItem } from '@/store/constants';
import Vue from 'vue';
import AvatarCard from '@/components/AvatarCard.vue';
import { Horse } from '@/store/modules/horse';
import { SPACES_HOME } from '@/router';
import { ERROR_LIST } from '@/helpers/errors';
import { mdiHorseVariant, mdiPlus } from '@mdi/js';
import { shortStringDots } from '@/helpers/utils';
import { SportActivity } from '@/store/modules/sport';
import SportActivityEditCard from '@/components/modules/sport/SportActivityEditCard.vue';
import SportActivityDetails from '@/components/modules/sport/SportActivityDetails.vue';
import { CalendarEvent } from '@/store/modules/calendar';
import { TYPE } from '@/store/modules/notification';

interface Data {
  routine: SportActivity;
  sportActivities: SportActivity[];
  horse: Horse | null;
  editSportProgramDialog: boolean;
  loading: boolean;
  calendarEvents: CalendarEvent[];
  calendarEvent: CalendarEvent;
}

interface Methods {
  addRoutineToCalendar(routine: SportActivity): void;

  deleteCalendarEvent(referenceId: string): void;

  updateCalendarEvent(calendarEvent: CalendarEvent): void;

  replicateActivity(activity: SportActivity): void;

  loadItems(): void;
}

interface Computed {
  sport: ModuleItem;
  mdiHorseVariant: string;
  horseNameTrimmed: string;
  mdiPlus: string;
}

export default Vue.extend<Data, Methods, Computed, Record<string, never>>({
  name: 'Sport',
  components: {
    SportActivityDetails,
    SportActivityEditCard,
    AvatarCard,
    ModuleFrame
  },
  data: () => ({
    routine: {
      activity: '',
      duration: '',
      observation: '',
      dateStart: '',
      professional: '',
      rating: 0
    } as SportActivity,
    horse: null,
    loading: false,
    editSportProgramDialog: false,
    calendarEvents: [],
    sportActivities: [],
    calendarEvent: {
      id: '',
      referenceId: '',
      name: 'Atividade',
      editable: false,
      feature: 'routine',
      moduleName: MODULE_TYPES.SPORT.name,
      color: MODULE_TYPES.SPORT.color,
      icon: MODULE_TYPES.SPORT.icon,
      start: new Date().toISOString(),
      details: ''
    } as CalendarEvent
  }),
  async created() {
    const horseId = this.$router.currentRoute.params.horseId;
    this.horse = this.$store.getters['HorseModule/getById'](horseId);
    this.$store
      .dispatch('SportModule/loadSportActivityFromHorse', horseId)
      .then((activities) => {
        if (activities) {
          this.sportActivities = activities;
        } else {
          this.sportActivities = [];
        }
      })
      .catch(() => {
        this.sportActivities = [];
      });
    if (!this.horse) {
      const spaceId = this.$router.currentRoute.params.spaceId;
      this.$router.push({
        name: SPACES_HOME,
        params: { spaceId: spaceId },
        query: { error: ERROR_LIST.HORSE_NO_EXISTS.code.toString() }
      });
    }
  },
  methods: {
    loadItems() {
      const horseId = this.$router.currentRoute.params.horseId;
      // this.$store
      //   .dispatch('SportModule/loadSportActivityFromHorse', horseId)
      //   .then((activities) => {
      //     if (activities) {
      //       this.sportActivities = activities;
      //     } else {
      //       this.sportActivities = [];
      //     }
      //   })
      //   .catch(() => {
      //     this.sportActivities = [];
      //   });
    },
    addRoutineToCalendar(routine) {
      if (this.horse && this.horse.id) {
        this.loading = true;

        this.$store
          .dispatch('SportModule/addSportActivityToHorse', {
            activity: routine,
            horseId: this.horse.id
          })
          .then((sportActivity) => {
            const newCalendarEvent = Object.assign({}, this.calendarEvent);
            newCalendarEvent.referenceId = sportActivity.id;
            this.$store.dispatch('CalendarModule/addWCalendarEventToHorse', {
              horseId: this.horse?.id,
              referenceId: routine.id,
              calendarEvent: newCalendarEvent
            });
            this.loading = false;
            this.editSportProgramDialog = false;
            this.calendarEvent.details = '';
            this.calendarEvent.start = '';
            this.calendarEvent.id = '';
            this.calendarEvent.referenceId = '';
            this.$store.commit('NotificationModule/add', {
              msg: 'Atividade adicionada com sucesso',
              type: TYPE.SUCCESS
            });
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$rollbar.error(error);
            this.loading = false;
          });
        this.loading = false;
        this.editSportProgramDialog = false;
      }
    },
    deleteCalendarEvent(referenceId) {
      if (this.horse && this.horse.id) {
        this.$store.dispatch(
          'CalendarModule/deleteCalendarEventByReferenceId',
          {
            horseId: this.horse.id,
            eventId: referenceId
          }
        );
      }
    },
    updateCalendarEvent(calendarEvent) {
      if (this.horse && this.horse.id) {
        calendarEvent.horseId = this.horse.id;
        this.$store.dispatch('CalendarModule/updateCalendarEvent', {
          event: calendarEvent
        });
      }
    },
    replicateActivity(activity) {
      this.routine.activity = '';
      this.routine.activity = activity.activity;
      this.routine.professional = activity.professional;
      this.routine.duration = activity.duration;
      this.routine.observation = activity.observation;
      this.editSportProgramDialog = true;
    }
  },
  computed: {
    sport() {
      return MODULE_TYPES.SPORT;
    },
    mdiHorseVariant() {
      return mdiHorseVariant;
    },
    horseNameTrimmed() {
      return this.horse ? shortStringDots(this.horse.name, 12) : '';
    },
    mdiPlus() {
      return mdiPlus;
    }
  }
});
