
import Vue, { PropType } from "vue";
import { HorseWeight } from "@/store/modules/horse";
import { ApexOptions } from "apexcharts";

export interface ChartData {
  x: number;
  y: number;
}

export interface ChartSeries {
  name: string;
  data: ChartData[];
}
interface Data {
  chartOptions: ApexOptions;
}
interface Props {
  weights: HorseWeight[] | undefined | null;
}

interface Computed {
  series: ChartSeries[];
  sortedWeights: HorseWeight[];
}

export default Vue.extend<Data, Record<string, unknown>, Computed, Props>({
  name: 'WeightsDetail',
  data: function () {
    return {
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Historico de pesagens',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          title: {
            text: 'Weight in Kg'
          },
          min: 0,
          max: 1000
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      }
    };
  },
  computed: {
    sortedWeights() {
      let weights = this.weights?.slice();
      if (weights) {
        weights.sort(function (a, b) {
          const timeB = new Date(b.weightInDate);
          const timeA = new Date(a.weightInDate);
          return timeB.getTime() - timeA.getTime();
        });
      } else {
        weights = [];
      }
      return weights;
    },
    series() {
      let series: ChartSeries[] = [];
      let chartData: ChartData[] = [];
      this.sortedWeights.forEach((weight: HorseWeight) => {
        let yz = Number(weight.weight);
        let xz = new Date(weight.weightInDate).getTime();
        let cData: ChartData = {
          x: xz,
          y: yz
        };
        chartData.push(cData);
      });
      let cSerie: ChartSeries = {
        name: 'Peso',
        data: chartData
      };
      series.push(cSerie);
      return series;
    }
  },
  props: {
    weights: {
      type: Array as PropType<HorseWeight[]>,
      default: undefined
    }
  }
});
